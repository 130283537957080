"use strict";
import $ from 'jquery';
window.jQuery = $;
import 'slick-carousel';
import 'bootstrap';

import Navigation from './components/navigation';
import Carousel from './components/carousel';
import Select from './components/select';

jQuery(document).ready(() => {
    Carousel();
    Select();
    Navigation();
    
    $('.js-fade-in-up:in-viewport').addClass('o-fade-in-up');

    //Click states to show different classes
    $(".card__fav").click(function (e) {
        e.preventDefault();
        $(this).toggleClass('is-active');
    });
});

$(window).scroll(function () {
    $('.js-fade-in-up:in-viewport').addClass('o-fade-in-up');
});
