export default function Navigation() {

    $('#menu-button').on('click', function (e) {
        $(this).toggleClass('is-active');
        $(this).parent().toggleClass('is-active');
        $('#navigation-wrapper').toggleClass('is-active');
        $('#top-wrapper').toggleClass('is-active');
        $('body, #main-wrapper').toggleClass('no-scroll');
    });



    
};  