export default function Carousel() {
    $('.carousel').each(function (index, sliderWrap) {
        var $slider = $(sliderWrap).find('.carousel__slides');
        $slider.slick({
            dots: false,
            arrows: true,
            speed: 300,
            slidesToShow: 8.2,
            draggable: true,
            infinite: false,
            touchThreshold: 200,
            responsive: [
            {
              breakpoint: 1800,
              settings: {
                slidesToShow: 7.2,
              }
            },
            {
              breakpoint: 1650,
              settings: {
                slidesToShow: 6.5,
              }
            },
            {
              breakpoint: 1480,
              settings: {
                slidesToShow: 5.5,
              }
            },

            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 4.5,
              }
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 4.5,
                arrows: false,
              }
            },
            {
              breakpoint: 880,
              settings: {
                slidesToShow: 3.5,
                infinite: false,
                arrows: false,
              }
            },

            {
              breakpoint: 715,
              settings: {
                slidesToShow: 2.8,
                infinite: false,
                arrows: false,
              }
            },
            {
              breakpoint: 650,
              settings: {
                slidesToShow: 2.2,
                infinite: false,
                arrows: false,
              }
            },

            {
              breakpoint: 400,
              settings: {
                slidesToShow: 1.5,
                infinite: false,
                arrows: false,
              }
            }
          ]
        });
    }); 

    $('.carousel-cards').each(function (index, sliderWrap) {
        var $slider = $(sliderWrap).find('.cards__carousel');
        var progressBar = $(sliderWrap).find('.progress__line');

        //for first time load
        $slider.on('init', function (event, slick, currentSlide, nextSlide) {
          var $currentDot = $(".slick-dots .slick-active").index() + 1;
          var $dots = $slider.find('.slick-dots li').length;
          var $calc = ($currentDot / $dots) * 100;
          progressBar.css('background-size', $calc + '% 100%').attr('aria-valuenow', $calc);
        });
  
        $slider.on('afterChange', function (event, slick, currentSlide, nextSlide) {
          var $currentDot = $slider.find(".slick-dots .slick-active").index() + 1;
          var $dots = $slider.find('.slick-dots li').length;
          var $calc = ($currentDot / $dots) * 100;

          progressBar.css('background-size', $calc + '% 100%').attr('aria-valuenow', $calc);
        });

        $slider.slick({
            dots: true,
            arrows: true,
            speed: 300,
            infinite: false,
            slidesToShow: 8,
            slidesToScroll: 1,
            autoplay: false,
            draggable: true,
            centerMode: false,
            pauseOnHover: false,
            touchThreshold: 200,
            responsive: [
            {
              breakpoint: 1600,
              settings: {
                slidesToShow: 7,
              }
            },
            {
              breakpoint: 1480,
              settings: {
                slidesToShow: 5.2,
              }
            },
            {
              breakpoint: 1100,
              settings: {
                slidesToShow: 4.5,
              }
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 4.5,
                arrows: false,
              }
            },
            {
              breakpoint: 880,
              settings: {
                slidesToShow: 3.5,
                infinite: false,
                arrows: false,
              }
            },
            {
              breakpoint: 650,
              settings: {
                slidesToShow: 2,
                infinite: false,
                arrows: false,
              }
            }
          ]
        });

    });

    $('.carousel-brands').each(function (index, sliderWrap) {
        var $slider = $(sliderWrap).find('.cards__carousel');
        var progressBar = $(sliderWrap).find('.progress__line');

        //for first time load
        $slider.on('init', function (event, slick, currentSlide, nextSlide) {
          var $currentDot = $(".slick-dots .slick-active").index() + 1;
          var $dots = $slider.find('.slick-dots li').length;
          var $calc = ($currentDot / $dots) * 100;
          progressBar.css('background-size', $calc + '% 100%').attr('aria-valuenow', $calc);
        });
  
        $slider.on('afterChange', function (event, slick, currentSlide, nextSlide) {
          var $currentDot = $slider.find(".slick-dots .slick-active").index() + 1;
          var $dots = $slider.find('.slick-dots li').length;
          var $calc = ($currentDot / $dots) * 100;

          progressBar.css('background-size', $calc + '% 100%').attr('aria-valuenow', $calc);
        });

        $slider.slick({
            dots: true,
            arrows: true,
            speed: 300,
            infinite: false,
            slidesToShow: 8,
            slidesToScroll: 1,
            autoplay: false,
            draggable: true,
            centerMode: false,
            pauseOnHover: false,
            touchThreshold: 200,
            responsive: [
            {
              breakpoint: 1600,
              settings: {
                slidesToShow: 7,
              }
            },
            {
              breakpoint: 1449,
              settings: {
                slidesToShow: 5.2,
              }
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 4.5,
                arrows: false,
              }
            },
            {
              breakpoint: 880,
              settings: {
                slidesToShow: 3.5,
                infinite: false,
                arrows: false,
              }
            },
            {
              breakpoint: 650,
              settings: {
                slidesToShow: 1.5,
                infinite: false,
                arrows: false,
              }
            }
          ]
        });

    });

    
};  